import { commonError, infoMessage } from "../utils/ErrorHandler/errorHandler";
import axios from "axios";
import { isMobile } from "../utils/utils";
import { Info } from "@material-ui/icons";
import { getDataserverUrl, isProd } from "../utils/envSelector";
import StorageManager from "../utils/StorageManager";
import { addEvent } from "./Events/EventsApi";
import {
  chooseToDisconnect,
  connectToChat,
  disconnectSocket,
  getToken,
  initiateSocket,
  sendMessage,
} from "./assistant-connection";

// const baseurlcvserver = "http://localhost:5000";
const baseurlcvserver = "https://cv-test.anteia.co";
// const baseurldataserver = "http://localhost:8080";
const baseurldataserver = getDataserverUrl();
const dev = !isProd();
const esperaEntreFrames = 300;
const esperaEntreCapturas = 1000;
const numImages = 2;
const numStepsBeforeSkip = 2;
const url = {
  // mobilenet: 'https://storage.googleapis.com/tfjs-models/tfjs/mobilenet_v1_0.25_224/model.json',
  // modelfull: process.env.PUBLIC_URL + '/tensorflowjsModels/fulllModel/model/my-model.json',
  modelface:
    process.env.PUBLIC_URL + "/tensorflowjsModels/faceModel/model.json",
  modelid: process.env.PUBLIC_URL + "/tensorflowjsModels/idModel/model.json",
  modelhand:
    process.env.PUBLIC_URL + "/tensorflowjsModels/handModel/model.json",
};

export default class Models {
  constructor(
    token,
    tf,
    webcamfinal,
    registrationId,
    // drawOnCanvas,
    // setLoading,
    drawCuadradito,
    eraseCuadradito,
    repeatStep,
    acceptStep,
    nextBioReq,
    matiApi,
    sendToPhone,
    setColorCuadrito,
    api,
    ShowLoader,
    modelId,
    modelFace,
    country,
    addHintMsg,
    addFailImg,
    addFailCode
  ) {
    this.token = token;
    this.tf = tf;
    this.webcamfinal = webcamfinal;
    this.modelface = undefined;
    // this.modelfull = undefined;
    this.modelhand = undefined;
    this.modelid = undefined;
    // this.mobilenet = undefined;
    // this.drawOnCanvas = drawOnCanvas;
    // this.setLoading = setLoading;
    this.drawCuadradito = drawCuadradito;
    this.eraseCuadradito = eraseCuadradito;
    this.repeatStep = repeatStep; //When a step fails, repeat it
    this.acceptStep = acceptStep; //When a step is successful, accept it
    this.nextBioReq = nextBioReq;
    this.matiApi = matiApi;
    this.ccFrontUs = false;
    this.ccBackUs = false;
    this.ccFrontMati = false;
    this.ccBackMati = false;
    this.ccFrontCount = 0;
    this.ccBackCount = 0;
    this.faceCount = 0;
    this.sendToPhone = sendToPhone;
    this.isLoading = false;
    this.setColorCuadrito = setColorCuadrito;
    this.api = api;
    this.altFlowAct = false;
    this.needsFace = true;
    this.modelid = modelId;
    this.modelface = modelFace;

    this.addHintMsg = addHintMsg;
    //Save stuff to session
    this.storage = new StorageManager();
    if (registrationId && registrationId !== "") {
      this.registrationId = registrationId;
      this.storage.setId(this.registrationId);
    }

    if (country && country !== "") {
      this.country = country;
      this.storage.setCountry(this.country);
    }

    this.frontImg = "";
    this.backImg = "";
    this.addFailImg = addFailImg;
    this.addFailCode = addFailCode;
    this.agentCalled = false;
    this.sessionId = "";
    this.agentRequests = [];
    this.timeoutId = "";
  }

  getToken() {
    console.log("getting token");
    if (this.token && this.token !== "") {
      console.log("got token from memory");
      return this.token;
    } else {
      let token2 = this.storage.getToken();
      if (token2 !== "") {
        console.log("got token from storage");
        this.token = token2;
        return this.token;
      }
    }
  }

  getCountry() {
    console.log("getting country");
    if (this.country && this.country !== "") {
      console.log("got country from memory");
      return this.country;
    } else {
      let token2 = this.storage.getCountry();
      if (token2 !== "") {
        console.log("got country from storage");
        this.country = token2;
        return this.country;
      }
    }
  }

  getId() {
    console.log("getting Id");
    if (this.registrationId && this.registrationId !== "") {
      console.log("got Id from memory");
      return this.registrationId;
    } else {
      let token2 = this.storage.getId();
      if (token2 !== "") {
        console.log("got Id from storage");
        this.registrationId = token2;
        return this.registrationId;
      }
    }
  }

  async loadModel() {
    // return true;
    if (this.isLoading) return true;
    this.isLoading = true;

    console.log("Loading Models....");
    try {
      if (!this.modelid) {
        this.modelid = await this.tf.loadLayersModel(url.modelid);
        console.log("Loaded model modelid");
        const warmupResult = this.modelid.predict(
          this.tf.zeros([1, 244, 244, 3])
        );
        warmupResult.dataSync();
        warmupResult.dispose();
      }

      if (!this.modelface) {
        this.modelface = await this.tf.loadLayersModel(url.modelface);
        console.log("Loaded model modelface");
        const warmupResult = this.modelface.predict(
          this.tf.zeros([1, 244, 244, 3])
        );
        warmupResult.dataSync();
        warmupResult.dispose();
      }

      if (this.modelface && this.modelid) {
        console.log("Load models success");
        this.isLoading = false;
        return true;
      } else {
        this.isLoading = false;
        // infoMessage("Error cargando redes, necesitamos reiniciar el proceso");
        return false;
      }
    } catch (err) {
      this.isLoading = false;
      console.error(err);
      return false;
    }
  }

  setwebcamfinal(webcam) {
    this.webcamfinal = webcam;
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getTime() {
    var d = new Date();
    return d.getTime();
  }

  GREEN = "#00ff44";
  GRAY = "#ffffff6f";
  NO_TEXT = "#ffffff6f";
  // RED = "#ff5d52";

  RED = "#ffffff5f";
  YELLOW = "#fff352";

  getErrorTextDoc(code) {
    if (!code) return "No logramos validar tu documento";
    const errors = {
      500: "Ocurrió un error de red, verifica tu conexión",
      100: "No logramos validar tu documento",
      101: "Intenta acercar más tu documento",
      102: "Hay un reflejo en tu documento",
      103: "Está borroso, no vemos tu documento",
      104: "No logramos validar tu documento",
      105: "Muestra el lado solicitado de tu documento",
      106: "El documento que mostraste no corresponde a un documento válido",
      107: "Detecté más de un documento, por favor muestra solo uno",
    };
    return errors[String(code)];
  }

  getErrorTextFace(code) {
    if (!code) return "No logramos validar tu rostro";
    const errors = {
      500: "Ocurrió un error de red, verifica tu conexión",
      103: "Está borroso, no vemos bien tu rostro",
      104: "No vemos bien tu rostro",
      107: "Vemos más de una persona, solo debes verte tu",
      108: "Ilumina mejor tu rostro, evita que se vean sombras",
    };
    return errors[code];
  }

  needsAgent() {
    //get project
    return this.storage.getProject().hasHumanAssistant;
  }

  async contactAgent() {
    this.startAgentTimer();
    if (this.agentCalled) return;
    this.agentCalled = true;
    console.log("Contacting Agent");
    //get token
    let token = await getToken();
    console.log("Got Token: " + token);
    //Initiate socket
    initiateSocket(token);
    //Connect to chat
    connectToChat(
      this.handleAgentMsg.bind(this),
      this.handleAgentAccept.bind(this),
      false,
      this.getId()
    );
    console.log("Agent contacted");
  }

  handleAgentMsg(error, msg) {
    this.stopAgentTimer();
    console.log("AGENT MESSAGE INCOMING");
    console.log(msg);
    msg.replace("<br>", "");
    console.log("AGENT MESSAGE END");
    let code = "";
    let hint = "";
    if (msg.split(":").length > 1) {
      code = msg.split(":")[0];
      hint = msg.split(":")[1];
    } else {
      code = "100";
      hint = msg;
    }
    if (this.agentRequests.length > 0) {
      this.addFailCode(code);
      this.addHintMsg(hint);
      this.rejectLastStep();
    } else {
      console.log("Ignoring msg because not waiting for stuff");
    }
  }

  rejectLastStep() {
    let step = this.agentRequests[0];
    this.agentRequests = this.agentRequests.slice(1);
    console.log("Rejecting Last Step: " + step);
    this.repeatStep(parseInt(step));
    this.sendAgentRequests();
  }

  handleAgentAccept(accepted, id) {
    console.log("Agent accepted: " + accepted);
    console.log("Session ID: " + id);
    this.sessionId = id;
    this.sendAgentRequests();
  }

  sendAgentRequests() {
    if (this.agentRequests.length > 0) {
      let step = this.agentRequests[0];
      console.log("Sending Agent Request for step: " + step);
      this.sendMessageLocal(step);
    } else {
      return;
    }
  }

  disconnectAgent() {
    chooseToDisconnect("Ended Succesfully");
  }

  startAgentTimer() {
    console.log("Starting Agent Timer...");
    this.stopAgentTimer();
    this.timeoutId = setTimeout(this.acceptAllWaiting.bind(this), 1000 * 60);
    console.log("Agent Timer Started");
  }

  stopAgentTimer() {
    console.log("Stopping Agent Timer...");
    if (this.timeoutId !== "") {
      clearTimeout(this.timeoutId);
    }
  }

  acceptAllWaiting() {
    console.log("Accepting All Waiting");
    this.agentRequests.forEach((e) => {
      this.acceptStep(e);
    });
    chooseToDisconnect("Timeout, byee");
  }

  sendMessageLocal(msg) {
    let msg2 = msg;
    if (msg === 5) msg2 = "CC-FRONT";
    if (msg === 6) msg2 = "CC-BACK";
    sendMessage(msg2, this.sessionId);
  }

  //Manejar Documento frente
  async handleCCFront(res) {
    // infoMessage(this.getErrorTextDoc(100));
    console.log("Respuesta FRONT NEW");
    console.log(res);
    this.ccFrontCount++;
    //Skips the step
    // if (true) {
    if (this.ccFrontCount >= numStepsBeforeSkip && !res.Result) {
      if (this.ccFrontCount <= numStepsBeforeSkip && this.needsAgent()) {
        this.agentRequests.push(5);
        this.addFailImg(this.frontImg);
        this.contactAgent();
        this.sendAgentRequests();
        addEvent("DOCUMENT_BACK_AGENT", {}, {}, "interaction");
        return;
      } else {
        this.api.log("Skipping CEDULA FRONT: " + res, "handleCCFront");
        addEvent("DOCUMENT_FRONT_SKIPPED", {}, {}, "interaction");
        this.acceptStep(5);
        return;
      }
    }

    console.log(res);
    if (!res.Result) {
      this.addHintMsg(this.getErrorTextDoc(res.errorCode));
      this.addFailCode(res.errorCode);
      this.addFailImg(this.frontImg);
      addEvent(
        "DOCUMENT_FRONT_REJECT",
        {
          reason: this.getErrorTextFace(res.errorCode),
          code: res.errorCode,
        },
        {},
        "interaction"
      );
      this.repeatStep(5);
    } else {
      addEvent("DOCUMENT_FRONT_ACCEPT", {}, {}, "interaction");
      this.acceptStep(5);
    }
  }

  //Manejar Documento reverso
  async handleCCBack(res) {
    // infoMessage("Respuesta BACK NEW");
    console.log("Respuesta BACK NEW");
    console.log(res);
    this.ccBackCount++;
    //Skips the step

    //Skips the step or calls agent
    // if (true) {
    if (this.ccBackCount >= numStepsBeforeSkip && !res.Result) {
      if (this.ccBackCount <= numStepsBeforeSkip && this.needsAgent()) {
        this.agentRequests.push(6);
        this.addFailImg(this.backImg);
        addEvent("DOCUMENT_BACK_AGENT", {}, {}, "interaction");
        this.contactAgent();
        this.sendAgentRequests();
        return;
      } else {
        this.api.log("Skipping CEDULA BACK: " + res, "handleCCBack");
        addEvent("DOCUMENT_BACK_SKIPPED", {}, {}, "interaction");
        this.acceptStep(6);
        return;
      }
    }

    if (!res.Result) {
      this.addHintMsg(this.getErrorTextDoc(res.errorCode));
      this.addFailCode(res.errorCode);
      this.addFailImg(this.backImg);
      addEvent(
        "DOCUMENT_BACK_REJECT",
        {
          reason: this.getErrorTextFace(res.errorCode),
          code: res.errorCode,
        },
        {},
        "interaction"
      );
      this.repeatStep(6);
    } else {
      addEvent("DOCUMENT_BACK_ACCEPT", {}, {}, "interaction");
      this.acceptStep(6);
    }
  }

  async predictid(type, paso) {
    if (this.altFlowAct) {
      document.getElementById("next").click();
      return;
    }
    this.setColorCuadrito(this.GRAY);
    console.log("Init> Predict Id!: " + paso);
    console.log(this.tf.getBackend());

    var count = 1;
    let imagesBuffer = [];
    let isPredicting = true;
    await this.webcamfinal.setupBack();

    let initialTime = null;
    await this.sleep(4000);
    this.drawCuadradito();
    this.setColorCuadrito(this.RED);
    // await this.sleep(1500);
    while (isPredicting && !this.altFlowAct) {
      try {
        const predictedClass = this.tf.tidy(() => {
          const img = this.webcamfinal.capture();
          if (!img || !this.modelid) {
            return null;
          }
          const predictions = this.modelid.predict(img);
          return predictions.as1D(); //.argMax();
        });
        var classId = true;
        if (predictedClass !== null) {
          const score = (await predictedClass.data())[0];
          classId = score > 0.55;
        } else {
          console.log("PredictedClass IS NULL");
          return;
        }

        var predictionText = "";
        // classId = false;
        switch (classId) {
          case false:
            if (!initialTime) initialTime = this.getTime();
            this.setColorCuadrito(this.YELLOW);
            predictionText = "I see an ID";
            // console.log(predictionText);
            // Wait for x seconds
            const secondsToWait = 4;
            let dif = this.getTime() - initialTime;
            // console.log("time dif: " + dif);
            if (dif < secondsToWait * 1000) {
              break;
            }
            await this.sleep(500);
            //Add Image to buffer
            let l = imagesBuffer.push(this.webcamfinal.captureSend());
            if (l === 1) {
              this.setColorCuadrito(this.GREEN);
              console.log("sendImageServer");
              if (type === "cedula-front") {
                //Send to cv
                // this.acceptStep(5);
                this.frontImg = imagesBuffer[0];
                this.sendNewFront(imagesBuffer).then((res) => {
                  this.handleCCFront(res);
                });
              } else {
                // this.acceptStep(6);
                this.backImg = imagesBuffer[0];
                this.sendNewBack(imagesBuffer).then((res) => {
                  this.handleCCBack(res);
                });
              }
              isPredicting = false;
              imagesBuffer = [];
              break;
            }
            break;
          default:
            this.setColorCuadrito(this.RED);
            // imagesBuffer = [];
            await this.sleep(500);
            predictionText = "I do not see an ID";
            console.log(predictionText);
            initialTime = this.getTime();
            break;
        }

        predictedClass.dispose();
        await this.tf.nextFrame();
      } catch (e) {
        console.log(e);
        await this.loadModel();
        this.sleep(500);
      }
    }
    // FIN
    this.setColorCuadrito(this.GREEN);
    await this.sleep(1500);
    this.eraseCuadradito();
    await this.sleep(500);
    this.setColorCuadrito(this.RED);
    if (!this.altFlowAct) document.getElementById("next").click();
  }

  async predictMock(type, paso) {
    this.setColorCuadrito(this.GRAY);
    console.log("Init> Predict MOCK!: " + paso);
    console.log(this.tf.getBackend());

    var count = 1;
    let imagesBuffer = [];
    let isPredicting = true;
    await this.webcamfinal.setupBack();

    let initialTime = null;
    await this.sleep(500);
    this.drawCuadradito();
    this.setColorCuadrito(this.RED);
    await this.sleep(5000);
    while (isPredicting && !this.altFlowAct) {
      try {
        const predictedClass = this.tf.tidy(() => {
          const img = this.webcamfinal.capture();
          if (!img || !this.modelid) {
            return null;
          }
          const predictions = this.modelid.predict(img);
          return predictions.as1D(); //.argMax();
        });
        var classId = true;
        if (predictedClass !== null) {
          const score = (await predictedClass.data())[0];
          // console.log(score);
          classId = score > 0.6;
        } else {
          console.log("PredictedClass IS NULL");
          return;
        }
        var predictionText = "";
        classId = false;
        switch (classId) {
          case false:
            if (!initialTime) initialTime = this.getTime();
            this.setColorCuadrito(this.YELLOW);
            predictionText = "I see an ID";
            // console.log(predictionText);
            // Wait for x seconds
            const secondsToWait = 5;
            let dif = this.getTime() - initialTime;
            // console.log("time dif: " + dif);
            if (dif < secondsToWait * 1000) {
              break;
            }
            //Add Image to buffer
            let l = imagesBuffer.push(this.webcamfinal.captureSend());
            if (l === 1) {
              this.setColorCuadrito(this.GREEN);
              isPredicting = false;
              imagesBuffer = [];
              break;
            }
            break;
          default:
            this.setColorCuadrito(this.RED);
            this.sleep(500);
            predictionText = "I do not see an ID";
            console.log(predictionText);
            initialTime = this.getTime();
            break;
        }

        predictedClass.dispose();
        await this.tf.nextFrame();
      } catch (e) {
        console.log(e);
        await this.loadModel();
        this.sleep(500);
      }
    }
    // FIN
    this.setColorCuadrito(this.GREEN);
    await this.sleep(1500);
    this.eraseCuadradito();
    await this.sleep(500);
    this.setColorCuadrito(this.RED);
    this.acceptStep(paso);
    if (!this.altFlowAct) document.getElementById("next").click();
  }

  async StartCameraCaptureMobile(typePass) {
    console.log("StartCameraCaptureMobile: " + typePass);

    if (typePass === 3 || typePass === 2) {
      this.drawCuadradito();
      await this.webcamfinal.setup();
      this.setColorCuadrito(this.NO_TEXT);
    } else {
      this.drawCuadradito();
      await this.webcamfinal.setupBack();
      this.setColorCuadrito(this.GRAY);
    }
  }

  //Manejar Cara
  async handleFace(res) {
    console.log("Respuesta FACE NEW");
    console.log(res);
    this.faceCount++;
    if (this.faceCount === numStepsBeforeSkip) {
      this.api.log("Skipping FACE: " + res, "handle Face");
      addEvent("FACE_SKIPPED", {}, {}, "interaction");
      console.log("Skipping step... FACE 2");
      this.acceptStep(2);
      return;
    }

    if (!res.Result) {
      this.addHintMsg(this.getErrorTextFace(res.errorCode));
      addEvent(
        "FACE_REJECT",
        { error: this.getErrorTextFace(res.errorCode), code: res.errorCode },
        {},
        "interaction"
      );
      this.repeatStep(2);
    } else {
      addEvent("FACE_ACCEPT", {}, {}, "interaction");
      this.acceptStep(2);
    }
  }

  async handleAuth(res, code) {
    console.log("Respuesta AUTH");
    console.log(res);
    console.log(res.similarity);
    this.faceCount++;
    if (this.faceCount === numStepsBeforeSkip) {
      this.api.log("Skipping FACE: " + res, "handle Face");
      console.log("Skipping step... FACE 2");
      this.acceptStep(3);
      return;
    }
    await this.api.setAuthResult(code, res.similarity);
    if (!res.Result) {
      this.addHintMsg("No podemos validar tu identidad, intenta de nuevo");
      this.repeatStep(3);
    } else if (res.Result && res.similarity < 80) {
      this.addHintMsg("No podemos verificar que seas tu, intenta de nuevo");
      this.repeatStep(3);
    } else {
      this.acceptStep(3);
    }
  }

  async predictface(type, step, id, code) {
    console.log("Init> Predict Face!");
    var count = 1;
    let imagesBuffer = [];
    let isPredicting = true;
    await this.webcamfinal.setup();
    if (!this.modelid || !this.modelface) {
      await this.loadModel();
    }
    // this.apiStats.setStep(type);
    await this.sleep(500);
    this.drawCuadradito();
    this.setColorCuadrito(this.RED);
    await this.sleep(1500);
    let initialTime = null;
    while (isPredicting) {
      try {
        const predictedClass = this.tf.tidy(() => {
          const img = this.webcamfinal.capture();
          if (!img) {
            return null;
          }
          const predictions = this.modelface.predict(img);
          return predictions.as1D(); //.argMax();
        });
        var classId = true;
        if (predictedClass !== null) {
          const score = (await predictedClass.data())[0];
          classId = score > 0.5;
          // console.log(score);
        } else {
          console.log("PredictedClass IS NULL");
          return;
        }

        var predictionText = "";
        // classId = false;
        switch (classId) {
          case false:
            if (!initialTime) initialTime = this.getTime();
            this.setColorCuadrito(this.YELLOW);
            predictionText = "I see a Face";
            if (count === numStepsBeforeSkip) {
              isPredicting = false;
              // await this.setNeedsManualRevision();
              this.setDescription("");
              break;
            }
            const secondsToWait = 5;
            let dif = this.getTime() - initialTime;
            // console.log("time dif: " + dif);
            if (dif < secondsToWait * 1000) {
              // console.log("Skipping because time: " + dif + "ms");
              break;
            }
            //Add Image to buffer
            let l = imagesBuffer.push(this.webcamfinal.captureSend());
            await this.sleep(1000);
            if (l === numImages) {
              this.setColorCuadrito(this.GREEN);
              // this.apiStats.addTry();

              if (type === "cara" && step === 2) {
                // this.acceptStep(2);
                this.sendNewFace(imagesBuffer).then((res) => {
                  this.handleFace(res);
                });
              } else {
                this.sendImageAuth(imagesBuffer, id).then((res) => {
                  this.handleAuth(res, code);
                });
              }
              isPredicting = false;
              imagesBuffer = [];
            }
            console.log(predictionText);
            break;
          default:
            this.setColorCuadrito(this.RED);
            predictionText = "I do not see a face";
            initialTime = this.getTime();
            await this.sleep(200);
            // imagesBuffer = [];
            console.log(predictionText);
            break;
        }
        predictedClass.dispose();
        await this.tf.nextFrame();
      } catch (e) {
        console.log(e);
        await this.loadModel();
      }
    }
    this.setColorCuadrito(this.GREEN);
    await this.sleep(1500);
    this.eraseCuadradito();
    await this.sleep(500);
    this.setColorCuadrito(this.RED);
    document.getElementById("next").click();
  }

  async predictfaceMock(type, step, id, code) {
    console.log("Init> Predict Face!");
    var count = 1;
    let imagesBuffer = [];
    let isPredicting = true;
    await this.webcamfinal.setup();
    if (!this.modelid || !this.modelface) {
      await this.loadModel();
    }
    // this.apiStats.setStep(type);
    await this.sleep(500);
    this.drawCuadradito();
    this.setColorCuadrito(this.RED);
    await this.sleep(1500);
    let initialTime = null;
    while (isPredicting) {
      try {
        const predictedClass = this.tf.tidy(() => {
          const img = this.webcamfinal.capture();
          if (!img) {
            return null;
          }
          const predictions = this.modelface.predict(img);
          return predictions.as1D(); //.argMax();
        });
        var classId = true;
        if (predictedClass !== null) {
          const score = (await predictedClass.data())[0];
          classId = score > 0.5;
          // console.log(score);
        } else {
          console.log("PredictedClass IS NULL");
          return;
        }

        var predictionText = "";
        // classId = false;
        switch (classId) {
          case false:
            if (!initialTime) initialTime = this.getTime();
            this.setColorCuadrito(this.YELLOW);
            predictionText = "I see a Face";
            if (count === numStepsBeforeSkip) {
              isPredicting = false;
              // await this.setNeedsManualRevision();
              this.setDescription("");
              break;
            }
            const secondsToWait = 5;
            let dif = this.getTime() - initialTime;
            // console.log("time dif: " + dif);
            if (dif < secondsToWait * 1000) {
              // console.log("Skipping because time: " + dif + "ms");
              break;
            }
            //Add Image to buffer
            let l = imagesBuffer.push(this.webcamfinal.captureSend());
            await this.sleep(1000);
            if (l === numImages) {
              this.setColorCuadrito(this.GREEN);
              await this.sleep(3000);
              this.acceptStep(2);
              isPredicting = false;
              imagesBuffer = [];
            }
            console.log(predictionText);
            break;
          default:
            this.setColorCuadrito(this.RED);
            predictionText = "I do not see a face";
            initialTime = this.getTime();
            await this.sleep(200);
            // imagesBuffer = [];
            console.log(predictionText);
            break;
        }
        predictedClass.dispose();
        await this.tf.nextFrame();
      } catch (e) {
        console.log(e);
        await this.loadModel();
      }
    }
    this.setColorCuadrito(this.GREEN);
    await this.sleep(1500);
    this.eraseCuadradito();
    await this.sleep(500);
    this.setColorCuadrito(this.RED);
    document.getElementById("next").click();
  }

  async getBackVersion() {
    var url = baseurlcvserver + "/version";

    var result = await axios({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    }).catch((err) => {
      console.error(err);
    });
    return result.data;
  }

  async sendNewFront(images) {
    addEvent("DOCUMENT_FRONT_SEND", {}, {}, "interaction");
    try {
      return await this.saveImage(images[0], "cedula-front", this.ccFrontCount);
    } catch (e) {}
    // return this.sendImageServer(images, "cedula-front", this.ccFrontCount);
  }

  async sendNewBack(images) {
    addEvent("DOCUMENT_BACK_SEND", {}, {}, "interaction");
    try {
      return await this.saveImage(images[0], "cedula-back", this.ccBackCount);
    } catch (e) {}
    // return this.sendImageServer(images, "cedula-back", this.ccBackCount);
  }

  async sendNewFace(images) {
    addEvent("FACE_SEND", {}, {}, "interaction");
    try {
      return await this.saveImage(images[0], "cara", this.faceCount);
    } catch (e) {}
    // return this.sendImageServer(images, "cara", this.faceCount);
  }

  /**
   * Saves an image using the dataserver
   * @param {*} image
   * @param {*} type
   * @param {*} count
   * @returns
   */
  async saveImage(image, type, count) {
    console.log("SAVING IMAGE: " + type);
    var data = {
      file: image,
    };

    var url = baseurldataserver + "/savePicture/" + type + "_" + count + "_new";
    var result = await axios({
      method: "post",
      url: url,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    }).catch((err) => {
      console.error(err);
      if (type === "cedula-front") {
        addEvent("DOCUMENT_FRONT_ERROR", { error: err.message }, {}, "error");
      } else if (type === "cedula-back") {
        addEvent("DOCUMENT_BACK_ERROR", { error: err.message }, {}, "error");
      } else if (type === "cara") {
        addEvent("FACE_ERROR", { error: err.message }, {}, "error");
      }
      this.api.log("Error: " + err, "sendImageServer: " + type);
    });
    console.log(result);
    if (result) {
      if (!(result.status === 200 || result.status === 201)) {
        this.api.log("Error: " + result.status, "sendImageServer: " + type);
        if (type === "cedula-front") {
          addEvent(
            "DOCUMENT_FRONT_ERROR",
            { error: `response with ${result.status}` },
            {},
            "error"
          );
        } else if (type === "cedula-back") {
          addEvent(
            "DOCUMENT_BACK_ERROR",
            { error: `response with ${result.status}` },
            {},
            "error"
          );
        } else if (type === "cara") {
          addEvent(
            "FACE_ERROR",
            { error: `response with ${result.status}` },
            {},
            "error"
          );
        }
        return {
          errorCode: 500,
          Message: "Intentemos de nuevo",
        };
      }
      console.log("SUCCESS SAVING IMAGE!");
      return result.data;
    }
    if (type === "cedula-front") {
      addEvent(
        "DOCUMENT_FRONT_ERROR",
        { error: "response was null" },
        {},
        "error"
      );
    } else if (type === "cedula-back") {
      addEvent(
        "DOCUMENT_BACK_ERROR",
        { error: "response was null" },
        {},
        "error"
      );
    } else if (type === "cara") {
      addEvent("FACE_ERROR", { error: "response was null" }, {}, "error");
    }
    this.api.log("Error: result undefined", "sendImageServer: " + type);
    return {
      errorCode: 500,
      Message: "Intentemos de nuevo",
    };
  }

  async sendImageServer(images, type, count) {
    return {
      Message: "Intentemos de nuevo",
    };
    console.log("SENDING IMAGE TO CV: " + type);
    console.log("Env is: " + dev);
    console.log("Country is:" + this.getCountry());
    var data = {
      img: images,
      id: this.getId(),
      env: dev,
      count: count,
      countryCode: this.getCountry(),
    };
    var data2 = {
      id: data.id,
      env: data.env,
      count: data.count,
      countryCode: data.countryCode,
    };

    var url = baseurlcvserver + "/" + type;
    console.log("NUMBER OF IMAGES TO SEND: " + images.length);
    console.log(data2);
    var result = await axios({
      method: "post",
      url: url,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    }).catch((err) => {
      console.error(err);
      if (type === "cedula-front") {
        addEvent("DOCUMENT_FRONT_ERROR", { error: err.message }, {}, "error");
      } else if (type === "cedula-back") {
        addEvent("DOCUMENT_BACK_ERROR", { error: err.message }, {}, "error");
      } else if (type === "cara") {
        addEvent("FACE_ERROR", { error: err.message }, {}, "error");
      }
      this.api.log("Error: " + err, "sendImageServer: " + type);
    });
    // this.setLoading(false);
    if (result) {
      if (!(result.status === 200 || result.status === 201)) {
        this.api.log("Error: " + result.status, "sendImageServer: " + type);
        if (type === "cedula-front") {
          addEvent(
            "DOCUMENT_FRONT_ERROR",
            { error: `response with ${result.status}` },
            {},
            "error"
          );
        } else if (type === "cedula-back") {
          addEvent(
            "DOCUMENT_BACK_ERROR",
            { error: `response with ${result.status}` },
            {},
            "error"
          );
        } else if (type === "cara") {
          addEvent(
            "FACE_ERROR",
            { error: `response with ${result.status}` },
            {},
            "error"
          );
        }
        return {
          Message: "Intentemos de nuevo",
        };
      }
      var response = result.data;
      console.log(response);
      return response;
    }
    if (type === "cedula-front") {
      addEvent(
        "DOCUMENT_FRONT_ERROR",
        { error: "response was null" },
        {},
        "error"
      );
    } else if (type === "cedula-back") {
      addEvent(
        "DOCUMENT_BACK_ERROR",
        { error: "response was null" },
        {},
        "error"
      );
    } else if (type === "cara") {
      addEvent("FACE_ERROR", { error: "response was null" }, {}, "error");
    }
    this.api.log("Error: result undefined", "sendImageServer: " + type);
    return {
      Message: "Intentemos de nuevo",
    };
  }

  async sendImageAuth(images, id) {
    console.log("SENDING IMAGE TO AUTH: " + id);
    var data = {
      img: images,
    };
    var url = baseurlcvserver + "/comparar_autenticacion/" + id;
    console.log("NUMBER OF IMAGES TO SEND: " + images.length);
    var result = await axios({
      method: "post",
      url: url,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      console.error(err);
      this.api.log("Error: " + err, "sendImageAuth");
    });
    // this.setLoading(false);

    if (result) {
      if (!(result.status === 200 || result.status === 201)) {
        this.api.log("Error: " + result.status, "sendImageAuth");
        return {
          Message: "Intentemos de nuevo",
        };
      }
      var response = result.data;
      console.log(response);
      return response;
    }
    this.api.log("Error: result undefined", "sendImageAuth");
    return {
      Message: "Intentemos de nuevo",
    };
  }

  async getPuntosCara(imgArr) {
    var data = { img: imgArr };
    console.log(data);
    console.log(this.token);
    var url = baseurlcvserver + "/puntos_cara";
    console.log("NUMBER OF IMAGES TO SEND PUNTOS_CARA: " + imgArr.length);
    var result = await axios({
      method: "post",
      url: url,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    });
    var response = result.data;
    console.log(response);
    return response;
  }

  async getComparar() {
    console.log(this.token);
    var url = baseurlcvserver + "/comparar/" + this.registrationId;
    console.log("GETTING: COMPARAR");
    var result = await axios({
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    });
    var response = await result.data;
    console.log(response);
    return response;
  }

  async sendVideoServer(data) {
    var url = baseurlcvserver + "/video";
    var result = await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    });
    var response = await result.data;
    console.log(response);
  }

  async sendVideo(formData) {
    console.log("SENDING VIDEO...");
    var url = baseurldataserver + "/video";
    var result = await axios({
      method: "post",
      url: url,
      data: formData,
      headers: {
        Authorization: "Bearer " + this.token,
      },
    }).catch(function (error) {
      // alert(
      //   "Error, no se pudo almacenar correctamente la información. Se reiniciará el proceso"
      // );
    });
    console.log(result);
    if (!result || !(result.status === 200 || result.status === 201)) {
      // alert("Error enviando audio");
    }
  }

  async sendAudio(formData) {
    console.log("SENDING AUDIO...");
    var url = baseurldataserver + "/audio";
    var result = await axios({
      method: "post",
      url: url,
      data: formData,
      headers: {
        Authorization: "Bearer " + this.token,
      },
    });
    console.log(result);
    if (!result || !(result.status === 200 || result.status === 201)) {
      // alert("Error enviando audio");
    }
  }

  async setNeedsManualRevision() {
    var url = baseurldataserver + "/registerFlow/needsManualRevision";
    var result = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: "Bearer " + this.token,
      },
    });
    console.log(result);
  }

  async predicthand(type) {
    return;
    console.log("Init> Predict Hand!");
    // INICIO
    this.apiStats.setStep(type);
    this.apiStats.startDuracion(type);
    var count = 1;
    let imagesBuffer = [];
    let isPredicting = true;
    await this.webcamfinal.setup();
    if (!this.modelid || !this.modelface || !this.modelhand) {
      await this.loadModel();
    }
    this.apiStats.setStep(type);

    while (isPredicting) {
      const predictedClass = this.tf.tidy(() => {
        const img = this.webcamfinal.capture();
        const predictions = this.modelhand.predict(img);
        // const predictions = this.modelhand.predict(activation);
        return predictions.as1D(); //.argMax();
      });
      const score = (await predictedClass.data())[0];
      const classId = score > 0.5;
      // console.log(score);
      // window.alert(score)
      var predictionText = "";
      switch (classId) {
        case false:
          if (count === numStepsBeforeSkip) {
            isPredicting = false;
            await this.setNeedsManualRevision();
            this.setDescription("");
            break;
          }
          predictionText = "I see a Hand";
          console.log(predictionText);
          this.sleep(2000);
          //Add Image to buffer
          let l = imagesBuffer.push(this.webcamfinal.captureSend());
          if (l === numImages) {
            this.apiStats.addTry();
            var msg = await this.sendImageServer(imagesBuffer, type, count);
            if (msg.Message === "" || msg.Message === null) {
              isPredicting = false;
              this.setDescription("");
              this.apiStats.setPaso(type, true);
            } else {
              count++;
              predictionText = msg.Message;
              this.setDescription(msg.Message);
            }

            imagesBuffer = [];
          }
          await this.sleep(esperaEntreCapturas);
          break;
        default:
          predictionText = "I do not see a Hand";
          console.log(predictionText);
          this.sleep(esperaEntreFrames);
          break;
      }

      predictedClass.dispose();
      await this.tf.nextFrame();
    }
    // FIN
    this.apiStats.endDuracion(type);
    var json = await this.getBackVersion();
    console.log(json.version);
    this.apiStats.sendStats(type, this.token, json.version);
    document.getElementById("next").click();
  }
}
