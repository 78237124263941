import React from "react";
import "./App.css";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as HashRouter, Switch, Route } from "react-router-dom";
import General from "./General";
import VerifyCode from "./components/VerifyCode";
import AppBar from "./components/AppBar";
import AppRouter from "./AppRouter";

function App() {
  const reload = () => window.location.reload();

  return (
    <div>

<AppRouter/>

{/* <General2/>
       <AppBar />
      <HashRouter basename="/">
        <Switch>
 <Route exact path="/verifycode">
            <VerifyCode />
          </Route>
          <Route exact path="*">
            <General />
          </Route>
          <Route
            path="/tensorflowjsModels/fulllModel/model/my-model.json"
            onEnter={reload}
          />
          <Route
            path="/tensorflowjsModels/handModel/model/my-model.json"
            onEnter={reload}
          />
          <Route
            path="/tensorflowjsModels/faceModel/model/my-model.json"
            onEnter={reload}
          />
          <Route
            path="/tensorflowjsModels/idModel/model/my-model.json"
            onEnter={reload}
          />   
     
        </Switch>
      </HashRouter>  */}
    </div>
  );
}

export default App;
