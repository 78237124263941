import React from "react";
// import icon1 from "../static/a2censo-SOLO-bvc-logo-BLANCO.png";
// import iconres from "../static/NEW-LOGO-LISTAS-RESTRICTIVAS.png";
// import iconna from "../static/NEW-LOGO-LISTAS-NACIONALES.png";
import iconre from "../static/REVISA-TU-CELU.png";

import iconar from "../static/LOGO CHECK NEW.png";
import iconFold from "../static/folder.png";
import iconLoad from "../static/load.png";
import iconvison from "../static/vision.png";

import iconArrow from "../static/icon-arrow.png";
import iconRegistra from "../static/registraduria-nacional-seeklogo.png";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { useTheme } from "@material-ui/core/styles";

function DivDescription(props) {
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up("md"));

  const getIconAR = () => {
    return (
      <div className="pl-2">
        <img src={iconar} alt="Icono" width="15px" height="10px"></img>
      </div>
    );
  };
  const getIconRE = () => {
    return (
      <div>
        <img src={iconre} alt="Icono" width="25px" height="25px"></img>
      </div>
    );
  };
  const getIconFold = () => {
    return (
      <div>
        <img src={iconFold} alt="Icono" width="15px" height="18px"></img>
      </div>
    );
  };
  const getIconVision = () => {
    return (
      <div>
        <img src={iconvison} alt="Icono" width="15px" height="18px"></img>
      </div>
    );
  };
  const getIconLoad = () => {
    return (
      <div>
        <img src={iconLoad} alt="Icono" width="15px" height="18px"></img>
      </div>
    );
  };

  const getIconREspecial = () => {
    return (
      <div>
        <img src={iconre} alt="Icono" width="30px" height="30px"></img>
      </div>
    );
  };
  const getIconArrow = () => {
    return (
      <div>
        <img src={iconArrow} alt="Icono" width="30px" height="30px"></img>
      </div>
    );
  };
  const getIconEntidades = () => {
    return (
      <div>
        <img
          className="imagEntidad"
          src={iconRegistra}
          alt="Icono"
          width="100%"
          height="100%"
        ></img>
      </div>
    );
  };

  const getDescription = (name, number) => {
    return (
      <div className="col-12 border-box">
<div className="row">
        <div className="col-3">{props.completeStep(number) && getIconAR()}</div>
        <div className="col-6 pl-2 pr-1">
          <h6 className="text-bio font-d-p">{name}</h6>
        </div>
        <div className="col-1 pl-0 pr-0"> {getIconRE()}</div>
      </div>
      </div>
    );
  };
  const getDescriptionSinDoc = (name) => {
    return (
      <div className="row">
        <div className="col-12">
        <div className="row ">
          
          <div className="col-3 p-0 d-flex"><div className="pt-1 tap"></div>{getIconAR()}</div>
          <div className="col-8 pr-0">
              <div className="row text-right">
                  <div className="col-12 "><div className="block"></div></div>
                  <div className="col-12"> <h6 className="text-box">{name}</h6></div>
              </div>
          </div>
      </div>
        </div>
        
        </div>
        
     
    );
  };
  return (<div></div>)
  return (
    <div className="container-fluid recuadro-general">
        <div className="este">
        <div className="row">
        <div className="col-lg-7 col-12 conts-biometry">
          <div className="d-flex" >
          <div className="border-box-top">
     {getDescriptionSinDoc("IDENTIDAD")}
   </div>
   <div className="border-box-top">
     {getDescriptionSinDoc("BIOMETRÍA")}
   </div>
   <div className="border-box-top">
     {getDescriptionSinDoc("CONTRATO")}
   </div>
          </div>
        </div>
        <div className="col-12 conts-derciptions">
              <div className="row">
                  <div className="col-lg-1">

                  </div>
                  <div className="col-lg-11">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-4 p-1">
                          {getDescription("ROSTRO", 3)}
                        </div>
                        <div className="col-lg-4 p-1">
                          {getDescription("MANO", 4)}
                        </div>
                        <div className="col-lg-4 p-1">
                          {getDescription("IRIS", 5)}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-4 p-1">
                        {getDescription("VOZ", 7)}
                        </div>
                        <div className="col-lg-4 p-1 font">
                          {getDescription("DOCUMENTO", 8)}
                        </div>
                        <div className="col-lg-4 p-1 font">
                        {getDescription("PASSWORD", 10)}
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-lg-4">
                       
                      <div className="row">
                        <div className="col-lg-7 p-1 mt-1 border-box">
                        <span>GEOREFERENCIACIÓN</span>
                        </div>
                        <div className="col-lg-4">  
                        <div className="row">
                                <div className="col-6">
                                <img className="iconFold" src={iconFold} />
                                </div>
                                <div className="col-6 pl-0">
                                <img src={iconLoad} className="iconLoad"/>
                                </div>
                        </div>
                        
                     
                        </div>
                      </div>
                    </div>
                  </div>
               
             
                  </div>
                  <div className="col-lg-1">

                  </div>
             </div>
            </div>
        
      </div>
   
        </div>
       
    </div>
    
  );
}

export default DivDescription;
