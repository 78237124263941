import React, { useState, useEffect } from "react";
import iconre from "../../static/message.png";
import useQuery from "../../hooks/useQuery";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  infoMessage,
  successMessage,
  withInputAndCall,
} from "../utils/ErrorHandler/errorHandler";
import QRCode from "qrcode.react";
import LoadingOverlay from "react-loading-overlay";
import icono2 from "../../static/LOGO NEXT NEW.png";
import "./QrFlowIntro.scss";

function QrFlowAuth(props) {
  const api = props.api;
  const url = window.location.href.replace("qrFlowAuth", "auth");
  console.log(url);
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const UrlCode = query.get("code");

  // const [loading, setLoading] = useState(true);
  const [smsSent, setSmsSent] = useState(false);
  const [showbtn, setShowbtn] = useState(false);

  const msgWait = "Esperando la verificación... ¡No cierres esta ventana!";
  const msgSuccess = "Redirigiendo...";
  const msgNonSuccess = "Ya puedes cerrar esta ventana";
  const [text, setText] = useState("");

  return (
    <div className="App instruccion-geo" style={{ color: "black" }}>
      <div
        className="row"
        style={{
          paddingLeft: "5%",
          textAlign: "center",
          color: "white",
        }}
      >
        <div className="col">
          <div className="row center"></div>
        </div>
      </div>
      <div className="row" style={{ padding: "0%" }}>
        <div
          className="col-12 pb-4 pt-6"
          style={{ paddingTop: "5%", textAlign: "center", color: "white" }}
        >
          <div className="row">
            <div
              className="col-lg-12 col-12"
              style={{ paddingRight: "10%", paddingLeft: "10%" }}
            >
              <h5>
                Escanea este código para realizar la autenticación desde tu
                celular
                <br />
              </h5>
              <h5>
                En caso de salirte del proceso desde tu celular, solo debes
                escanear de nuevo el código
                <br />
              </h5>
            </div>
            <br />
            <div className="col-lg-12 col-12 pb-5 pt-1">
              <QRCode
                value={url}
                style={{ marginTop: "1%" }}
                size={258}
                bgColor={"#FFFFFF"}
                fgColor={"#1d1e3b"}
                includeMargin={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrFlowAuth;
