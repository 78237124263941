import React, { useState, useEffect } from "react";
import iconre from "../../static/message.png";
import newMessage from "../../assets/newMessage.png";
import "./InstruccionGeo.scss";
import { Container, Row, Col } from "react-bootstrap";
import {
  infoMessage2,
  successMessage,
} from "../utils/ErrorHandler/errorHandler";
import { useHistory, Link } from "react-router-dom";
import { Button1 } from "../utils/Buttons/Buttons";
import useQuery from "../../hooks/useQuery";
import { addEvent } from "../api/Events/EventsApi";
function VerifyMailPage(props) {
  let history = useHistory();
  const [mostrarEnviar, setMostrarEnviar] = useState(false);

  const query = useQuery();
  const UrlCode = query.get("code");

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  console.log(props.email, "PROPSSS EMAILLLL");

  async function verifyOtp() {
    const api = props.api;
    var result = await api.didOtpAndMail();
    console.log("RESULTT>>>> : ", result);
    if (result) {
      successMessage("Correo confirmado!").then(() => {
        console.log("PROPS EMAIL:", props, "AND");
        // props.verifyOtp();
        // history.push(`direction/?code=${UrlCode}`);
        history.push(`vsms/?code=${UrlCode}`);
        props.setColorB(1);
        console.log(UrlCode, "URLCODE");
      });
    } else {
      console.log("No has confirmado tu correo!");
      infoMessage2("No has confirmado tu correo!");
    }
  }

  useEffect(() => {
    // props.api.sendEmail();
    addEvent("STEP_EMAIL", {}, {}, "interaction");
    props.api.sendEmailNew();
    showEnviar();
  }, []);

  async function showEnviar() {
    await sleep(5000);
    setMostrarEnviar(true);
  }

  async function resend() {
    addEvent("EMAIL_RESEND", {}, {}, "interaction");
    setMostrarEnviar(false);
    props.api.sendEmailNew();
    await infoMessage2("Correo reenviado!");
    showEnviar();
  }

  return (
    <div className="App" style={{ color: "black" }}>
      <Container className="container_instruccionGeo">
        <Row>
          <Col style={{ color: "white" }}>
            <p className="text_1">
              Te hemos enviado <br></br> un correo para que<br></br> continues
              tu proceso
            </p>
            <div>
              {/* ---------------------este icono viene del script html-----------------*/}

              <span
                class="iconify icon-e "
                data-icon="uil-envelope-add"
                data-inline="false"
              ></span>
              <p className="text_2">REVISA TU CORREO</p>
              {/* <img src={newMessage} alt="icon" /> */}
            </div>

            <p className="text_3">
              Una vez llegue este<br></br> correo puedes cerrar<br></br> esta
              ventana
            </p>

            <p className="text_4">
              {" "}
              Dentro de los siguientes 30 segundos <br></br> llegará este correo
              electrónico, si no <br></br>llega, oprime RE-ENVIAR CORREO
            </p>
            <div hidden={!mostrarEnviar}>
              <div style={{ marginTop: "1em" }}>
                <Button1
                  text="RE-ENVIAR CORREO"
                  onClick={resend}
                  width="70vw"
                  height="5vh"
                />
              </div>
              {/* <p className="text_5" onClick={resend}>
       RE-ENVIAR CORREO
     </p> */}
            </div>
            {props.canEdit && (
              <div style={{ marginTop: "1em" }}>
                <Link to={`email/?code=${UrlCode}`}>
                  <Button1
                    text="MODIFICAR CORREO"
                    onClick={props.setColorB(2)}
                    width="70vw"
                    height="5vh"
                  />
                </Link>
              </div>
              // <Link to={`email/?code=${UrlCode}`}>
              //   <button className="btn_modify" onClick={() =>{props.setColorB(2)}}>
              //     <h1>MODIFICAR CORREO</h1>
              //   </button>
              // </Link>
            )}

            <p className="text_4">
              Si por algun motivo no esta el correo en<br></br> tu bandeja de
              entrada revisa la carpeta<br></br>
              de spam
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default VerifyMailPage;
