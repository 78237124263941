import React, { useState } from "react";
// import { Icon, InlineIcon } from '@iconify/react';
// import envelopeAdd from '@iconify-icons/uil/envelope-add';
import newMessage from "../../assets/newMessage.png";
import { Link, useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import axios from "axios";
import "./ModifyEmail.scss";
// import { Row, Container } from "react-bootstrap";
function ModifyEmail(props) {

  let history = useHistory();
  const [editemail, setEditemail] = useState(props.email);
  const query = useQuery();
  const UrlCode = query.get("code");

  const Onchange = (e) => {
    setEditemail(e.target.value);
  };

  const onclick = () => {
    props.setEmail(editemail);
    props.api.modifyEmail(editemail);
    history.push(`/vemail?code=${UrlCode}`);
    props.setColorB(1)
  };

  return (
    <div className="m-email">
      {/* ---------------------este icono viene del script html-----------------*/}
      <span
        class="iconify icon-email"
        data-icon="uil-envelope-add"
        data-inline="false"
      ></span>

      <p>Ingresa tu correo</p>

      <div className="content-email">
        <input className="input-e" value={editemail} onChange={Onchange} />
      </div>
      <div>
        <button className="btn-siguiente" onClick={() => onclick()}>
          SIGUIENTE
        </button>
      </div>
    </div>
  );
}
export default ModifyEmail;
