import React from "react";
import iconoanteia from "../../assets/logo_anteia.png";
import "./IntroLogo.scss";
import { detect } from "detect-browser";
import { infoMessage } from "../utils/ErrorHandler/errorHandler";
const browser = detect();
function IntroLogo() {
  // if (browser.os === "iOS") {
  //   if (browser.name != "safari") {
  //     infoMessage("TU NAVEGADOR NO ES COMPATIBLE TE SUGERIMOS USAR SAFARI");
  //   }
  // } else if (browser.os === "android") {
  //   if (
  //     browser.name != "chrome" ||
  //     browser.name != "samsung" ||
  //     browser.name != "firefox"
  //   ) {
  //     infoMessage("TU NAVEGADOR NO ES COMPATIBLE TE SUGERIMOS USAR CHROME");
  //   }
  // } else {
  //   console.log(browser.os);
  // }

  // if (browser) {
  //   console.log(browser.name);
  //   console.log(browser.version);
  //   console.log(browser.os);
  // }
  return (
    <div className="intro_logo">
      <img src={iconoanteia} alt="logo_anteia" />
    </div>
  );
}
export default IntroLogo;
