import { commonError } from "../utils/ErrorHandler/errorHandler";
import axios from "axios";
import { getEnv, Envs, isProd } from "../utils/envSelector";

const matiOauth = "https://api.getmati.com/oauth";
const flowId = "605d02e9ff2ed1001b8f7877";
const matiInitVerification = "https://api.getmati.com/v2/verifications";

export default class MatiApi {
  constructor() {
    this.token = null;
    this.init = false;
    this.identity = null;
    this.front = null;
    this.back = null;
    this.face = null;
    this.didSend = false;
    this.country = null;
    this.api = null;
  }

  setCountry(country) {
    this.log("Setting Country: " + country);
    this.country = country;
  }

  getCountryMati(code) {
    const map = {
      COL: "CO",
      CR: "CR",
    };
    return map[code];
  }

  log(info) {
    console.log("MatiProvider>>>");
    console.log(info);
  }


  /**
   * Gets a mati token!
   */
  async getToken() {
    if(!isProd()) return;
    try {
      if (this.token) {
        return;
      }
      let matiUser = process.env.REACT_APP_YOUR_API_USER_MATI;
      let matiPassword = process.env.REACT_APP_YOUR_API_KEY_MATI;
      this.log("Getting Mati Token");
      let authStr = btoa(matiUser + ":" + matiPassword);
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic " + authStr,
      };
      var details = {
        grant_type: "client_credentials",
      };

      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      let result = await axios({
        method: "post",
        url: matiOauth,
        headers: headers,
        data: formBody,
      });
      let jsonResult = result.data;
      this.log(jsonResult);
      this.token = jsonResult.access_token;
    } catch (e) {
      console.log(e);
      this.api.log(e, "Mati:: getToken");
      commonError("Error creando registro...\nVolvamos a intentar!");
    }
  }

  async initVerification(registrationId) {
    if(!isProd()) return;
    try {
      if (!this.token) {
        await this.getToken();
      }
      if (this.init) {
        return;
      }
      this.log("Init Verification: " + registrationId);
      var body = {
        flowId: flowId,
        metadata: {
          registrationId: registrationId,
        },
      };
      let result = await axios({
        method: "post",
        url: matiInitVerification,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
        data: JSON.stringify(body),
      });
      this.log(result);
      if (result.status === 200 || result.status === 201) {
        let resJson = result.data;
        this.log(resJson);
        this.init = true;
        this.identity = resJson.identity;
      } else {
        this.log("ERRRORRR initVerification");
        this.log(result.status);
        commonError("Error creando registro...\nVolvamos a intentar!");
      }
    } catch (e) {
      console.log(e);
      this.api.log(e, "Mati:: initVerification");
      commonError("Error creando registro...\nVolvamos a intentar!");
    }
  }

  handleError(response) {
    // return true;
    if (response[0].error) {
      this.log("Error!");
      this.log(response[0].error.type);
      this.log(response[0].error.code);
      this.api.log(response[0].error.code, "Mati::" + response[0].error.type);
      return false;
    } else {
      this.log("Success!");
      return true;
    }
  }

  async sendFront(photo) {
    if(!isProd()) return true;
    try {
      let rand = this.getRandomInt(10000);
      this.log("SendFront...");
      this.front = photo;
      // return;
      const formData = new FormData();
      let inputs = [
        {
          inputType: "document-photo",
          group: 0,
          data: {
            type: "national-id",
            country: this.getCountryMati(this.country),
            region: "",
            page: "front",
            filename: rand + ".png",
          },
        },
      ];
      formData.append("inputs", JSON.stringify(inputs));
      formData.append("document", this.dataURItoBlob(photo), rand + ".png");

      let result = await axios({
        method: "post",
        url:
          "https://api.getmati.com/v2/identities/" +
          this.identity +
          "/send-input",
        headers: {
          Authorization: "Bearer " + this.token,
        },
        data: formData,
      });
      this.log("SendFront Response");
      this.log(result);
      if (result.status === 200 || result.status === 201) {
        let resJson = result.data;
        this.log(resJson);
        return this.handleError(resJson);
      } else {
        this.log("ERRRORRR sendPhoto front");
        this.log(result.status);
        this.api.log(result, "Mati:: getToken");
        return false;
      }
    } catch (e) {
      this.log("ERRRORRR sendPhoto front");
      console.log(e);
      this.api.log(e, "Mati:: sendfront");
      return false;
    }
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  async sendBack(photo) {
    if(!isProd()) return true;
    try {
      let rand = this.getRandomInt(10000);
      this.log("SendBack...");
      this.back = photo;
      // return;
      const formData = new FormData();
      let inputs = [
        {
          inputType: "document-photo",
          group: 0,
          data: {
            type: "national-id",
            country: this.getCountryMati(this.country),
            region: "",
            page: "back",
            filename: rand + ".png",
          },
        },
      ];
      formData.append("inputs", JSON.stringify(inputs));
      formData.append("document", this.dataURItoBlob(photo), rand + ".png");
      // formData.append("document", this.dataURItoBlob(this.front), "ccFront.png");
      let result = await axios({
        method: "post",
        url:
          "https://api.getmati.com/v2/identities/" +
          this.identity +
          "/send-input",
        data: formData,
        headers: {
          Authorization: "Bearer " + this.token,
        },
      });
      this.log("SendBack Response");
      this.log(result);
      if (result.status === 200 || result.status === 201) {
        let resJson = result.data;
        this.log(resJson);
        return this.handleError(resJson);
      } else {
        this.log("ERRRORRR sendPhoto back");
        this.log(result.status);
        this.api.log(result, "Mati:: sendBack");
        return false;
      }
    } catch (e) {
      console.log(e);
      this.api.log(e, "Mati:: sendBack");
      return false;
    }
  }

  async sendFace(photo) {
    if(!isProd()) return true;
    try {
      let rand = this.getRandomInt(10000);
      this.log("SendFace...");
      this.face = photo;
      const formData = new FormData();
      let inputs = [
        {
          inputType: "selfie-photo",
          data: {
            type: "selfie-photo",
            filename: rand + ".png",
          },
        },
      ];
      formData.append("inputs", JSON.stringify(inputs));
      formData.append("document", this.dataURItoBlob(photo), rand + ".png");
      let result = await axios({
        method: "post",
        url:
          "https://api.getmati.com/v2/identities/" +
          this.identity +
          "/send-input",
        data: formData,
        headers: {
          Authorization: "Bearer " + this.token,
        },
      });
      this.log("SendFace Response");
      this.log(result);
      if (result.status === 200 || result.status === 201) {
        let resJson = result.data;
        this.log(resJson);
        return this.handleError(resJson);
      } else {
        this.log("ERRRORRR sendPhoto face");
        this.log(result.status);
        this.api.log(result, "Mati:: sendFace");
        return false;
      }
    } catch (e) {
      this.api.log(e, "Mati:: sendFace");
      return false;
    }
  }

  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI);

    // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var mimeString = "image/png";

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
}
