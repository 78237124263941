import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputBase } from "@material-ui/core";

function RecolectDataStart2() {
  return (
    <div style={{ margin: "20px" }}>
      <form noValidate autoComplete="off">
        <TextField
          label="Size"
          id="outlined-size-normal"
          defaultValue="Normal"
          variant="outlined"
        />
      </form>
    </div>
  );
}
export default RecolectDataStart2;
