import React from "react";
import { Formik, Form } from "formik";
import { TextField } from "./../utils/TextField/TextField";
import * as Yup from "yup";
function TestFormik() {

  return (
    <Formik
    initialValues={{ email: "" }}
    onSubmit={async values => {
      await new Promise(resolve => setTimeout(resolve, 500));
      alert(JSON.stringify(values, null, 2));
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email()
        .required("Required")
    })}
  >
    {props => {
      const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset
      } = props;
      return (
        <form onSubmit={handleSubmit}>
          <label htmlFor="email" style={{ display: "block" }}>
            Email
          </label>
          <input
            id="email"
            placeholder="Enter your email"
            type="text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors.email && touched.email
                ? "https://gitlab.com/s.i.sas/db_backend.git"
                : "text-input"
            }
          />
          {errors.email && touched.email && (
            <div className="input-feedback">{errors.email}</div>
          )}

          <button
            type="button"
            className="outline"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            Reset
          </button>
          <button type="submit" disabled={isSubmitting}>
            Submit
          </button>

        
        </form>
      );
    }}
  </Formik>
  );
}
export default TestFormik;
