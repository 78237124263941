import React from "react";

import "./TextsFace.scss";

//   -------------------------COMMAND TEXTS BY FACE------------------------------------------------


export const CommandTextsByFace = ({ step, colorCuadrito }) => {
  console.log(step);
  console.log("texts start working by face  ...");
  let txtsFace = [
    ["MIRA FIJAMENTE TU PANTALLA"],
    ["ADQUIRIENDO TU HUELLA", "BIOMÉTRICA"],
    ["LISTO"],
  ];
  let txtRed= (
    <div className="txt-face">
      <h2 className="AVGARDNI txtf">{txtsFace[0]}</h2>
     
    </div>
  );
  let txtYellow = (
    <div className="txt-face">
      <h2 className="AVGARDNI txtf1">{txtsFace[1][0]}</h2>
      <h2 className="AVGARDNI txtf1">{txtsFace[1][1]}</h2>
  
    </div>
  );
  let txtGreen= (
    <div className="txt-ready">
      <h2 className="AVGARDNI txtr">{txtsFace[2]}</h2>
    
    </div>
  );

  if (colorCuadrito === "#ffffff5f" || colorCuadrito === "#ffffff6f") {
    return txtRed;
  } else if (colorCuadrito === "#fff352") {
    return txtYellow;
  } else if (colorCuadrito === "#00ff44") {
    return txtGreen;
  }
};

export const CommandTextsByFaceDown = ({ step, colorCuadrito }) => {
  console.log(step);
  console.log("texts start working by face  ...");
  let txtsFace = [
    ["UBICA TU ROSTRO", "DENTRO DE LA", "SILUETA"],
    ["CONTINUA VIENDO", "TU PANTALLA"],
    ["FINALIZA LA CAPTURA DE TU", "HUELLA BIOMÉTRICA"],
  ];
  let txtRed= (
    <div className="txt-face">
      <h2 className="AVGARDNI txtf">{txtsFace[0][0]}</h2>
      <h2 className="AVGARDNI txtf">{txtsFace[0][1]}</h2>
      <h2 className="AVGARDNI txtf">{txtsFace[0][2]}</h2>
    </div>
  );
  let txtYellow = (
    <div className="txt-face">
      <h2 className="AVGARDNI txtf1">{txtsFace[1][0]}</h2>
      <h2 className="AVGARDNI txtf1">{txtsFace[1][1]}</h2>
  
    </div>
  );
  let txtGreen= (
    <div className="txt-face">
      <h2 className="AVGARDNI txtf">{txtsFace[2][0]}</h2>
      <h2 className="AVGARDNI txtf">{txtsFace[2][1]}</h2>
    
    </div>
  );

  if (colorCuadrito === "#ffffff5f" || colorCuadrito === "#ffffff6f") {
    return txtRed;
  } else if (colorCuadrito === "#fff352") {
    return txtYellow;
  } else if (colorCuadrito === "#00ff44") {
    return txtGreen;
  }
};
// ---------------VERSION 2.06----------------------------------
/*   export const CommandTextsByFace = ({step}) => {
    console.log(step);
    console.log("texts start working by face  ...");
    let txtsFace = [
      ["Mira", "tu camara", "fijamente"],
      ["ACÉRCATE UN POCO MÁS", "A LA CÁMARA", "MÁS CERCA"],
      [
        "DI FUERTE & CLARO",
        "ESTE ES EL COLOR DE MI VOZ",
        "QUEREMOS ESCUCHAR TU VOZ",
      ],
    ];
    let txtFace = (
      <div className="txt-face">
       
        <h2 className="AVGARDNI txtf">{txtsFace[0][0]}</h2>
        <h2 className="AVGARDNI txtf">{txtsFace[0][1]}</h2>
        <h2 className="AVGARDNI txtf">{txtsFace[0][2]}</h2>
      </div>
    );
    let txtFace2 = (
      <div className="txt-face">
       
        <h2 className="AVGARDNI txtf1">{txtsFace[1][0]}</h2>
        <h2 className="AVGARDNI txtf1">{txtsFace[1][1]}</h2>
        <h2 className="AVGARDNI txtf2">{txtsFace[1][2]}</h2>
      </div>
    );
    let txtVoice = (
      <div className="txt-voice">
        <h2 className="AVGARDNI txtv">{txtsFace[2][0]}</h2>
        <h2 className="AVGARDNI txtv">{txtsFace[2][1]}</h2>
        
      </div>
    );

    if (step === 2) {
      return txtFace;
    } else if (step === 3) {
      return txtFace2;
    } else if (step === 4) {
      return txtVoice;
    }
  } */


