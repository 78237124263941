import React from "react";
import "./Buttons.scss";

export const Button = ({ text, onClick, width, height}) => {
  return (
    <>
      <button className='button' onClick={onClick} style={{width: width, height: height}}>{text}</button>
    </>
  );
};

export const Button1 = ({ text, onClick, width, height}) => {
  return (
    <>
      <button className="button1" onClick={onClick} style={{width: width, height: height}}>{text}</button>
    </>
  );
};