import { addEvent } from "../api/Events/EventsApi";

const url = {
  modelFace:
    process.env.PUBLIC_URL + "/tensorflowjsModels/faceModel/model.json",
  modelId: process.env.PUBLIC_URL + "/tensorflowjsModels/idModel/model.json",
  modelhand:
    process.env.PUBLIC_URL + "/tensorflowjsModels/handModel/model.json",
};

export default class ModelsLoader {
  constructor(tf) {
    this.modelId = undefined;
    this.modelFace = undefined;
    this.isLoading = false;

    // this.tf = tf;
  }

  async loadModels(tf) {
    this.tf = tf;

    if (this.isLoading) {
      return true;
    }
    this.isLoading = true;

    console.log("Loading Models in modelsLoader....");
    try {
      if (!this.modelId) {
        this.modelId = await this.tf.loadLayersModel(url.modelId);
        console.log("Loaded model modelid");
        const warmupResult = await this.modelId.predict(
          this.tf.zeros([1, 244, 244, 3])
        );
        warmupResult.dataSync();
        warmupResult.dispose();
      }

      if (!this.modelFace) {
        this.modelFace = await this.tf.loadLayersModel(url.modelFace);
        console.log("Loaded model modelFace");
        const warmupResult = this.modelFace.predict(
          this.tf.zeros([1, 244, 244, 3])
        );
        warmupResult.dataSync();
        warmupResult.dispose();
      }

      if (this.modelFace && this.modelId) {
        console.log("Load models success");
        addEvent("NEURAL_START", {}, {}, "interaction");
        this.isLoading = false;
        return true;
      } else {
        if (!this.modelFace && !this.modelId) {
          addEvent(
            "NEURAL_ERROR",
            { error: "ModelFace and ModelId Didn't load" },
            {},
            "error"
          );
        } else if (!this.modelFace && this.modelId) {
          addEvent(
            "NEURAL_ERROR",
            { error: "ModelFace Didn't load" },
            {},
            "error"
          );
        } else if (this.modelFace && !this.modelId) {
          addEvent(
            "NEURAL_ERROR",
            { error: "ModelId Didn't load" },
            {},
            "error"
          );
        }
        this.isLoading = false;
        // infoMessage("Error cargando redes, necesitamos reiniciar el proceso");
        return false;
      }
    } catch (err) {
      addEvent("NEURAL_ERROR", { error: err.message }, {}, "error");
      this.isLoading = false;
      console.error(err);
      return false;
    }
  }
}
