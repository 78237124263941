import { commonError } from "../utils/ErrorHandler/errorHandler";
import axios from "axios";
import { getDataserverUrl } from "../utils/envSelector";
import StorageManager from "../utils/StorageManager";

export default class DbConnection {
  constructor() {
    // this.baseUrl = "http://localhost:8080";
    this.baseUrl = getDataserverUrl();
    // this.baseUrl = "https://api.anteia.co";
    this.project = null;
    this.storageManager = new StorageManager();
  }

  getCountry(country) {
    const map = {
      COL: "CO",
      CR: "CR",
    };
    return map[country];
  }

  async getProjectAsync(
    code,
    after,
    matiApi,
    setNeedsOtp,
    setNeedsRut,
    setNeedsPassword,
    setNeedsPase,
    setNeedsTarjetaPropiedad,
    setCountry,
    setDocTypes
  ) {
    if (!this.project) {
      try {
        console.log("Get Project By Code!!!");
        //const http = new XMLHttpRequest();
        const url =
          this.baseUrl + "/registerFlow/getProjectByCode?code=" + code;
        console.log(url);
        var response = await axios({
          method: "get",
          url: url,
        });

        var json = response.data;
        //Saves the project to storage
        this.storageManager.setProject(json);
        console.log("/registerFlow/getProjectByCode?code=" + code);
        console.log(json);
        this.project = json;
        setNeedsOtp(json.hasOtp);
        console.log("NeedsRut?? " + json.needsRut);
        setNeedsRut(json.needsRut);
        matiApi.setCountry(json.country);
        setNeedsPassword(json.supportsAuth);
        setNeedsPase(json.needsPase);
        setNeedsTarjetaPropiedad(json.needsTarjetaPropiedad);
        setCountry(this.getCountry(json.country));
        setDocTypes(json.supportedDocTypes);
        await after(code, json);

        return json;
      } catch (e) {
        console.log("EEEEEEEEEEEEROOOOOOOOOOOOOOOOOOOOOOOOOOR");
        console.log(e);
        commonError("Su código es incorrecto, por favor verifique su acceso.");
      }
    }
  }
}
