import React from "react";
// import { Button } from "@material-ui/core";

function RecolectQuestions(props) {
  const texts = [
    [["AHORA VERIFIQUEMOS TU CELULAR Y CORREO"]],
    [
      ["TU LUGAR DE NACIMIENTO ES"],
      ["MEDELLÍN", "BOGOTÁ D.C.", "CALI", "IBAGUE"],
    ],
    [
      ["LA FECHA DE EXPEDICIÓN DE TU DOCUMENTO ES"],
      ["05/FEB/2018", "04/ENE/2018", "08/FEB/2018", "05/MAY/2018"],
    ],
    [
      ["TUS APELLIDOS SON"],
      ["FORERO ARIAS", "RUIZ FORERO", "FORERO CAMACHO", "CAMACHO JIMENEZ"],
    ],
    [["AHORA VERIFIQUEMOS TU CELULAR Y CORREO"]],
    [["AHORA VERIFIQUEMOS TU CELULAR Y CORREO"]],
  ];

  const getQuestion = (step) => {
    console.log(props.questions);
    console.log(step);
    // if((step-1)<props.questions.length&&(step)>0) {
    // return (
    //   <div >
    //     <h1>{props.questions[step-1].pregunta}</h1>
    //       <div className="row">
    //       <div className="col-2"></div>
    //       {
    //         ( props.questions[step-1].opciones.map(element => {
    //           return (
    //   <div className="col-2" style={{cursor: "pointer"}}>
    //   <Button
    //   onClick={props.nextPage}

    //       style={{   backgroundColor: "transparent", border: "none", color: "WHITE" }}>
    //     {element.text}
    //     </Button>

    //   </div>

    //           )
    //         }))
    //       }
    //       <div className="col-2"></div>
    //       </div>
    //       <h6>TU IDENTIDAD FUE VERIFICADA!</h6>
    //   </div>

    // );
    // }else{
    //   return (
    //     <div >
    //       <h1 style={{ paddingLeft: "5%", paddingRight: "5%" }}>{texts[(step===0)?0:5][0]}</h1>
    //     </div>

    //   );
    // }
    return (
      <div className="col-9 text">
        <div className="row justify-content-center">
          <h1 className="AVGARDNI pl-2 pr-2">{texts[step]}</h1>
        </div>
      </div>
    );
  };
  return (
    <div
      className="App pt-5 mt-5"
      style={{
        color: "white",
        fontFamily: "Arial",
        fontWeight: "Regular",
        opacity: 0.7,
      }}
    >
      <div className="row justify-content-center pr-2">{getQuestion(0)}</div>
    </div>
  );
}

export default RecolectQuestions;
