import React from "react";
import TextFinal from "../textFinal";
import "./TerminosAdvices.scss";
import { Link } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { Button } from "../utils/Buttons/Buttons";
import sun2 from "./../../assets/sun2.png";
import { addEvent } from "../api/Events/EventsApi";
import bulb1 from "./../../assets/bulb1.png";
import lamp1 from "./../../assets/lamp1.png";
function TerminosAdvices2() {
  const query = useQuery();
  const UrlCode = query.get("code");
  return (
    <div
      className="advices2"
      style={{
        color: "white",
        textAlign: "center",
      }}
    >
      <div className="advices2-txt">
        <TextFinal type="h1" txt={"Ubícate en"} />
        <TextFinal type="h1" txt={"un ambiente con"} />
        <TextFinal type="h1" txt={"BUENA LUZ"} />
      </div>
      <div>
        <img
          src={sun2}
          alt="sun"
          style={{
            width: "60px",
            height: "60px",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        />
        <img
          src={bulb1}
          alt="sun"
          style={{
            width: "60px",
            height: "60px",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        />
        <img
          src={lamp1}
          alt="sun"
          style={{
            width: "60px",
            height: "60px",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        />
      </div>
      <div className="advices2-txt">
        {/* <TextFinal type="h1" txt={"Así lograras"} />
        <TextFinal type="h1" txt={"la mejor nitidez"} />
        <TextFinal type="h1" txt={"que proporcione"} />
        <TextFinal type="h1" txt={"tu cámara al enfocar "} />
        <TextFinal type="h1" txt={"el documento"} />
         */}
        <TextFinal type="h1" txt={"Asegurate que la luz"} />
        <TextFinal type="h1" txt={"NO cause reflejos o se vea"} />
        <TextFinal type="h1" txt={"borroso tu documento"} />
      </div>
      <div>
        <Link to={`/advices3?code=${UrlCode}`}>
          <Button
            text="SIGUIENTE"
            width="177px"
            height="5vh"
            onClick={(e) => {
              addEvent("ADVICE_2", {}, {}, "interaction");
            }}
          />
        </Link>
      </div>
    </div>
  );
}
export default TerminosAdvices2;
