import React from "react";
import TextFinal from "../textFinal";
import "./TerminosAdvices.scss";
import { Link } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { Button } from "../utils/Buttons/Buttons";
import handsimg from "./../../assets/imghands.png";
import { addEvent } from "../api/Events/EventsApi";
function TerminosAdvices3() {
  const query = useQuery();
  const UrlCode = query.get("code");
  return (
    <div
      className="advices3"
      style={{
        color: "white",
        textAlign: "center",
      }}
    >
      {/* <div className='advices3-txt'>
            <TextFinal type="h1" txt={"Asegurate"} />
            <TextFinal type="h1" txt={"que puedas ver"} />
            <TextFinal type="h1" txt={"MUY BIEN"} />
             <TextFinal type="h1" txt={"tu documento en"} />
              <TextFinal type="h1" txt={"pantalla"} />
          </div> */}
      <div className="advices3-txt">
        <TextFinal type="h1" txt={"Coloca tu documento"} />
        <TextFinal type="h1" txt={"sobre una superficie"} />
        <TextFinal type="h1" txt={"plana antes de comenzar"} />
      </div>
      <img src={handsimg} alt="sun" style={{ width: "266px" }} />

      {/* <div className="advices3-txt">
        <TextFinal type="h1" txt={"Que la luz"} />
        <TextFinal type="h1" txt={"No cause reflejos o se vea"} />
        <TextFinal type="h1" txt={"borroso tu documento"} />
      </div> */}
      <div className="advices3-txt">
        <TextFinal type="h1" txt={"Y comprueba que"} />
        <TextFinal type="h1" txt={"puedes ver MUY BIEN"} />
        <TextFinal type="h1" txt={"tu documento"} />
      </div>
      <div className="advices3-txt">
        <TextFinal type="h2" txt={"Así lograrás la mejor nitidez que"} />
        <TextFinal type="h2" txt={"proporcione tu cámara al enfocar"} />
      </div>
      <div>
        <Link to={`/biometrics?code=${UrlCode}`}>
          <Button
            text="COMENCEMOS"
            width="177px"
            height="5vh"
            onClick={(e) => {
              addEvent("ADVICE_3", {}, {}, "interaction");
            }}
          />
        </Link>
      </div>
    </div>
  );
}
export default TerminosAdvices3;
