import { addEvent } from "../api/Events/EventsApi";
import { commonError } from "./ErrorHandler/errorHandler";
import { isIOS, isMobile } from "./utils";
export default class WebcamHTML {
  /**
   * @param {HTMLVideoElement} webcamElement A HTMLVideoElement representing the
   *     webcam feed.
   */
  constructor(webcamElement, tf, api, sendPhonePhoto) {
    this.webcamElement = webcamElement;
    this.tf = tf;
    this.lastStream = null;
    this.isBack = false;
    this.isFront = false;
    this.mediaRecorder = null;
    this.isRecording = false;
    this.recordedData = [];
    this.mimeType = "";
    this.models = null;
    this.api = api;
    this.captureFailed = false;
    this.sendPhonePhoto = sendPhonePhoto;
    this.didSend = false;
  }

  /**
   * Captures a frame from the webcam and normalizes it between -1 and 1.
   * Returns a batched image (1-element batch) of shape [1, w, h, c].
   */

  capture() {
    try {
      return this.tf.tidy(() => {
        // Reads the image as a Tensor from the webcam <video> element.
        const webcamImage = this.tf.browser.fromPixels(this.webcamElement);

        //Crop the image
        // const croppedImage = this.cropImage(webcamImage);

        //Resize Image
        // const reshapedImage = croppedImage.resizeNearestNeighbor([224, 224]);
        const reshapedImage = webcamImage.resizeNearestNeighbor([224, 224]);
        //Reverse the image
        const reversedImage = reshapedImage.reverse(1);

        // Expand the outer most dimension so we have a batch size of 1.
        const batchedImage = reversedImage.expandDims(0);
        // Normalize the image between -1 and 1. The image comes in between 0-255,
        // so we divide by 127 and subtract 1.
        // console.log(batchedImage.shape);
        return batchedImage
          .toFloat()
          .div(this.tf.scalar(127))
          .sub(this.tf.scalar(1));
      });
    } catch (e) {
      if (!this.captureFailed) {
        console.log(e);
        this.captureFailed = true;
        this.api.log("Error capturing frame: " + e, "webcam>>capture()");
        commonError(
          "No pudimos iniciar tu cámara, asegúrate de dar permisos y que la cámara esté disponible",
          () => this.sendPhonePhoto(true)
        );
        return null;
      } else {
        console.log(e);
        return null;
      }
    }
  }

  captureSend() {
    try {
      const canvas = document.createElement("canvas");
      // scale the canvas accordingly
      canvas.width = this.webcamElement.videoWidth;
      canvas.height = this.webcamElement.videoHeight;
      // draw the video at that frame
      canvas
        .getContext("2d")
        .drawImage(this.webcamElement, 0, 0, canvas.width, canvas.height);
      // convert it to a usable data URL
      const dataURL = canvas.toDataURL();
      const cleaned = dataURL.substr(22, dataURL.length);
      return cleaned;
    } catch (e) {
      this.api.log(
        "Error capturing to send camera front: " + e,
        "webcam>>captureSend()"
      );
    }
  }
  /**
   * Sleeps for ms milis
   * @param {*} ms
   * @returns
   */
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Crops an image tensor so we get a square image with no white space.
   * @param {Tensor4D} img An input image Tensor to crop.
   */

  cropImage(img) {
    const size = Math.min(img.shape[0], img.shape[1]);
    const centerHeight = img.shape[0] / 2;
    const beginHeight = centerHeight - size / 2;
    const centerWidth = img.shape[1] / 2;
    const beginWidth = centerWidth - size / 2;
    return img.slice([beginHeight, beginWidth, 0], [size, size, 3]);
  }

  /**
   * Adjusts the video size so we can make a centered square crop without
   * including whitespace.
   * @param {number} width The real width of the video element.
   * @param {number} height The real height of the video element.
   */

  adjustVideoSize(width, height) {
    console.log("Adjust Video Size:::w=" + width + ", h=" + height + ", ");

    const aspectRatio = width / height;
    if (width >= height) {
      this.webcamElement.width = aspectRatio * this.webcamElement.height;
    } else if (width < height) {
      this.webcamElement.height = this.webcamElement.width / aspectRatio;
    }
  }

  async stopCameras() {
    console.log("Stopping Cameras");
    if (this.lastStream) {
      this.lastStream.getTracks().forEach((track) => {
        console.log(track.id);
        track.stop();
        this.lastStream.removeTrack(track);
      });
    }
    this.lastStream = null;
    this.isRecording = false;
  }

  async startRecording(stream) {
    try {
      if (this.isRecording) return;
      if (this.didSend) return;
      console.log("Webcam:: Start Recording");
      var mediarecorder = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });
      this.mimeType = "video/webm";
      this.mediaRecorder = mediarecorder;
      this.mediaRecorder.start();
      this.isRecording = true;
      console.log("Webcam:: Recording Started!!!!");
    } catch (e) {
      this.isRecording = false;
      console.log(e);
      this.api.log("Error starting stream: " + e, "webcam>>StartRecording");
    }
  }

  async stopRecording() {
    try {
      if (!this.isRecording) return;
      if (this.didSend) return;
      this.isRecording = false;
      console.log("stopping recording...");
      let mediaRecorder = this.mediaRecorder;
      if (mediaRecorder === undefined) {
        return;
      }
      // Creates the data available handler, this executes one the media recorder is stopped
      var handleDataAvailable = ({ data }) => {
        console.log("HandleDataAvailable");
        console.log(data.size);
        if (data.size > 0) {
          this.recordedData.concat(data);
          const blob = new Blob([data], {
            type: this.mimeType,
          });
          const formData = new FormData();
          formData.append("file", blob, "video");
          this.models.sendVideo(formData);
        }
      };
      // sets the handler
      mediaRecorder.ondataavailable = function (chunk) {
        handleDataAvailable(chunk);
      };
      mediaRecorder.stop();
      console.log("recorder stopped, data available");
    } catch (e) {
      this.api.log("Error stopping stream: " + e, "webcam>>StopRecording");
      console.log(e);
    }
    this.didSend = true;
  }

  async setup(numTries = 1) {
    if (!isMobile()) {
      console.log("setup()->seupdBack()");
      return this.setupBack();
    }
    if (this.isFront) return new Promise((resolve, reject) => resolve());
    this.stopRecording();
    this.isFront = true;
    this.isBack = false;
    await this.stopCameras();
    console.log("SetupFROOOOOOOOOONT");
    const videoConst = {
      video: {
        // height: { min: 720 },
        // width: { min: 1280 },
        facingMode: "user",
      },
    };
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        // .getUserMedia({ video: true })
        .getUserMedia(videoConst)
        .then((stream) => {
          addEvent("CAMERA_FRONT_OPEN", {}, {}, "interaction");
          this.lastStream = stream;
          this.webcamElement.srcObject = stream;
          this.webcamElement.addEventListener(
            "loadeddata",
            async () => {
              this.adjustVideoSize(
                this.webcamElement.videoWidth,
                this.webcamElement.videoHeight
              );
              resolve();
            },
            false
          );
        })
        .catch((e) => {
          addEvent("CAMERA_FRONT_ERROR", { error: e.message }, {}, "error");
          console.log("Error starting camera front: " + e, "webcam>>setup()");
          this.api.log("Error starting camera front: " + e, "webcam>>setup()");
          this.api.log(
            "Error starting camera front: " + JSON.stringify(videoConst),
            "webcam>>setupBack()"
          );
          if (numTries === 3) {
            commonError(
              "No pudimos iniciar tu cámara, asegúrate de dar permisos y que la cámara esté disponible",
              () => this.sendPhonePhoto(true)
            );
            reject(e);
          } else {
            this.isFront = false;
            return this.setup(++numTries);
          }
        });
    });
  }

  /**
   * @returns A string list with the ids of the cameras
   */
  async getCameraIds() {
    console.log("getCameraIds()");
    let devices = await navigator.mediaDevices.enumerateDevices();

    let ids = [];
    console.log(devices);
    devices.forEach((device) => {
      if (device.kind === "videoinput") ids.push(device.deviceId);
    });
    console.log(ids);
    return ids;
  }

  async setupBack() {
    // commonError(
    //   "No pudimos iniciar tu cámara, asegúrate de dar permisos y que la cámara esté disponible",
    //   () => this.sendPhonePhoto(true)
    // );
    // return new Promise((resolve, reject) => resolve());
    if (this.isBack) return new Promise((resolve, reject) => resolve());
    let ids = await this.getCameraIds();
    this.isBack = true;
    this.isFront = false;
    await this.stopCameras();
    console.log("SetupBAaaaaack");

    const videoConst = {
      video: {
        height: { min: 720, ideal: 1080 },
        width: { min: 1280, ideal: 1920 },
        deviceId: { exact: ids[ids.length - 1] },
        // aspectRatio: { ideal: 1920 / 1080 },
        facingMode: "environment",
      },
    };
    const videoConstIOS = {
      video: {
        height: { min: 1080 },
        width: { min: 1920 },
        // aspectRatio: { ideal: 1920 / 1080 },
        facingMode: "environment",
      },
    };
    const videoConstPC = {
      video: {
        height: { min: 720 },
        width: { min: 1280 },
      },
    };
    var vidConst = isIOS() ? videoConstIOS : videoConst;
    vidConst = isMobile() ? vidConst : videoConstPC;
    console.log("VideoConst: ");
    console.log(vidConst);
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia(vidConst)
        .then((stream) => {
          addEvent("CAMERA_BACK_OPEN", {}, {}, "interaction");
          this.lastStream = stream;
          this.webcamElement.srcObject = stream;
          this.webcamElement.addEventListener(
            "loadeddata",
            async () => {
              this.startRecording(stream);
              this.adjustVideoSize(
                this.webcamElement.videoWidth,
                this.webcamElement.videoHeight
              );
              resolve();
            },
            false
          );
        })
        .catch((e) => {
          addEvent("CAMERA_BACK_ERROR", { error: e.message }, {}, "error");
          this.api.log(
            "Error starting camera back: " + e,
            "webcam>>setupBack()"
          );
          this.api.log(
            "Error starting camera back: " + JSON.stringify(vidConst),
            "webcam>>setupBack()"
          );
          commonError(
            "No pudimos iniciar tu cámara, asegúrate de dar permisos y que la cámara esté disponible",
            () => this.sendPhonePhoto(true)
          );
          reject(e);
        });
    });
  }
}
