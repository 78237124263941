import React, { useState } from "react";
import "./ModifyPhone.scss";
import { Link, useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
function ModifyPhone(props) {
  
  let history = useHistory();
  const [editphone, setEditphone] = useState(props.phone);
  const query = useQuery();
  const UrlCode = query.get("code");

  const Onchange = (e) => {
    setEditphone(e.target.value);
  };

  const onclick = () => {
    props.setPhone(editphone);
    props.api.modifyPhone(editphone);
    history.push(`/vsms?code=${UrlCode}`);
    props.setColorB(1)
  };

return (
    <div>
      <div className="m-phone">
        <p>Ingresa tu celular</p>

        <div className="content-phone">
          <input className="input-p" value={editphone} onChange={Onchange}/>
        </div>
        <div>
          <button className="btn-siguiente-p" onClick={()=>{onclick()}}>SIGUIENTE</button>
        </div>
      </div>
    </div>
  );
}
export default ModifyPhone;
