import React, { useState, useEffect } from "react";
import iconre from "../../static/message.png";
import useQuery from "../../hooks/useQuery";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  infoMessage,
  successMessage,
  withInputAndCall,
} from "./../utils/ErrorHandler/errorHandler";
import QRCode from "qrcode.react";
import LoadingOverlay from "react-loading-overlay";
import icono2 from "../../static/LOGO NEXT NEW.png";
import "./QrFlowIntro.scss";
import {
  hintMessageBorroso,
  hintMessageReflejo,
} from "../utils/ErrorHandler/hintMessages";

import {
  connectToChat,
  getToken,
  initiateSocket,
  sendMessage,
} from "../api/assistant-connection";

function QrFlowIntro(props) {
  const api = props.api;
  const url = window.location.href.replace("qrFlow", "") + "&qr=true";
  console.log(url);
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const UrlCode = query.get("code");

  // const [loading, setLoading] = useState(true);
  const [smsSent, setSmsSent] = useState(false);
  const [showbtn, setShowbtn] = useState(false);

  const msgWait = "Esperando la verificación... ¡No cierres esta ventana!";
  const msgSuccess = "Redirigiendo...";
  const msgNonSuccess = "Ya puedes cerrar esta ventana";
  const [text, setText] = useState("");
  const [sessId, setSessId] = useState("");

  useEffect(() => {
    // contactAgent();
    createCode();
    // hintMessageBorroso(
    //   "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+AkAAP8A+7Xdu1sAAAAASUVORK5CYII=",
    //   "#047bd6",
    //   "#000e1a"
    // );
  }, []);

  async function contactAgent() {
    // if (this.agentCalled) return;
    // this.agentCalled = true;
    console.log("Contacting Agent");
    //get token
    let token = await getToken();
    console.log("Got Token: " + token);
    //Initiate socket
    initiateSocket(token);
    //Connect to chat
    connectToChat(
      handleAgentMsg,
      handleAgentAccept,
      false,
      "61967220ccc4491e606cab19"
    );
  }

  function handleAgentMsg(error, msg) {
    console.log("AGENT MESSAGE INCOMING");
    console.log(msg);
    console.log("AGENT MESSAGE END");
  }
  function handleAgentAccept(accepted, id) {
    console.log("Agent accepted: " + accepted);
    console.log("Session ID: " + id);
    setSessId(id);
  }

  useEffect(() => {
    sendMessage("Hello There", sessId);
  }, [sessId]);

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function waitForDone() {
    var flag = true;
    var url2 = "";
    var blocked = false;
    while (flag) {
      await sleep(5000);
      // var res = await api.checkDone();
      // if (res.done) {
      //   flag = false;
      //   url2 = res.url;
      //   blocked = res.blocked;
      //   setLoading(false);
      // }
    }
    if (blocked) {
      setText(msgNonSuccess);
      setShowbtn(true);
      infoMessage(
        "Una de las fases de verificación no fue exitosa, nos pondremos en contacto contigo."
      ).then(() => {
        infoMessage("Ya puedes cerrar esta ventana");
      });
    } else {
      successMessage("Listo! Continuemos con el registro").then(() => {
        setText(msgSuccess);
        window.location = url2;
        setShowbtn(false);
        // window.location = url;
      });
    }
  }

  async function goBack() {
    var url2 = await api.getBackUrl();
    if (url2) window.location = url2;
  }

  async function createCode() {
    console.log(api.isQr());
    try {
      setLoading(true);
      await api.createCode();
      setText(msgWait);
      setShowbtn(false);
    } catch (e) {
      // setText(msgNonSuccess);
      // setShowbtn(true);
      // setLoading(false);
      // infoMessage("No pudimos registrar tu código QR :c");
    }

    waitForDone();
  }

  async function showSendSms() {
    if (!smsSent)
      withInputAndCall(
        "Enviar SMS",
        "Escribe tu número de celular (sin indicativo, ni espacios)",
        "Enviar",
        sendSMS,
        validateInput
      );
  }

  async function sendSMS(phone) {
    try {
      var res = await api.sendLink(phone).then(() => {
        setSmsSent(true);
        successMessage("SMS Enviado!");
      });
    } catch (e) {
      Swal.showValidationMessage(`Ocurrió un error enviando el SMS`);
    }
  }

  function validateInput(phone) {
    if (!phone) return "Escribe tu número";
    if (phone === "") return "Escribe tu número";
    if (phone.includes("+")) return "No escribas el indicativo";
    if (phone.length !== 8 && phone.length !== 10)
      return "Escribe un número válido";
    else return null;
  }

  return (
    <div className="App instruccion-geo" style={{ color: "black" }}>
      <div
        className="row"
        style={{
          paddingLeft: "5%",
          textAlign: "center",
          color: "white",
        }}
      >
        <div className="col">
          <div className="row center">
            <button
              type="button"
              className="btn btn-default acept"
              onClick={() => goBack()}
            >
              <img
                src={icono2}
                alt="Icono"
                width="30px"
                style={{ transform: "rotate(180deg)", marginRight: "20px" }}
              ></img>
              <h5>Cancelar y volver</h5>
            </button>
          </div>
        </div>
      </div>
      <div className="row" style={{ padding: "0%" }}>
        <div
          className="col-12 pb-4 pt-6"
          style={{ paddingTop: "5%", textAlign: "center", color: "white" }}
        >
          <div className="row">
            <div
              className="col-lg-12 col-12"
              style={{ paddingRight: "10%", paddingLeft: "10%" }}
            >
              <h5>
                Escanea este código para realizar el registro desde tu celular
                <br />
              </h5>
              <h5>
                En caso de salirte del proceso desde tu celular, solo debes
                escanear de nuevo el codigo
                <br />
              </h5>
            </div>
            <br />
            <div className="col-lg-12 col-12 pb-5 pt-1">
              <QRCode
                value={url}
                style={{ marginTop: "1%" }}
                size={258}
                bgColor={"#FFFFFF"}
                fgColor={"#1d1e3b"}
                includeMargin={true}
              />
            </div>
            <div className="col-12 verify">
              <h2>{text}</h2>
              {/* {showbtn && (
                <Link to={`/final?code=${UrlCode}`}>
                <button className="btn-continue">Continuar acá</button>
                </Link>
              )} */}

              <LoadingOverlay
                active={loading}
                // spinner={<BounceLoader />}
                spinner={true}
              />
            </div>
          </div>
        </div>
      </div>
      {loading && !smsSent && (
        <div
          // className="row"
          style={{
            marginTop: "2em",
            textAlign: "center",
            color: "white",
          }}
        >
          <div className="col">
            <p>Por favor no cierres esta página</p>
          </div>

          <div className="col">
            <button
              type="button"
              className="btn btn-default acept"
              onClick={() => showSendSms()}
            >
              <h5>¿O prefieres recibir un SMS?</h5>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default QrFlowIntro;
